
import { computed, defineComponent, ref, watch } from 'vue'
import { single } from '@/guides/endpoints/tip'
import { useRoute } from 'vue-router'
import BackBtn from '@/components/BackBtn.vue'
import ArticleHeader from '@/components/article/Header.vue'

export default defineComponent({
  name: 'tip',
  components: {
    BackBtn,
    ArticleHeader,
  },
  setup() {
    const data = ref()
    const route = useRoute()
    const slug = computed(() => route?.params?.slug)

    const fetch = () => {
      if (!slug.value) {
        return
      }

      single(slug.value).then(r => {
        data.value = r.data.content
      })
    }

    watch(() => slug.value, fetch)

    fetch()

    return {
      data,
    }
  },
})
