<template>
  <div class="tip fill-height" v-if="data">
    <BackBtn class="tip__back" />
    <div class="tip-inner--large">
      <ArticleHeader :data="data.header" />

      <div class="tip__content">
        <div v-if="data.body">
          <h3 class="mb-xxs">Description</h3>
          <div class="tip__content__text" v-html="data.body"></div>
        </div>

        <div class="tip__content__link-outer" v-if="data.footer.link">
          <h3>Lien utile</h3>
          <div class="tip__content__link">
            <div class="tip__content__link-inner">
              <span class="h4" v-html="data.footer.link"></span>
              <SvgSprite class="icon" symbol="icons-linkbis" size="16" />
            </div>
            <a
              class="tip__content__link__trigger"
              :href="data.footer.link"
              target="_blank"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { single } from '@/guides/endpoints/tip'
import { useRoute } from 'vue-router'
import BackBtn from '@/components/BackBtn.vue'
import ArticleHeader from '@/components/article/Header.vue'

export default defineComponent({
  name: 'tip',
  components: {
    BackBtn,
    ArticleHeader,
  },
  setup() {
    const data = ref()
    const route = useRoute()
    const slug = computed(() => route?.params?.slug)

    const fetch = () => {
      if (!slug.value) {
        return
      }

      single(slug.value).then(r => {
        data.value = r.data.content
      })
    }

    watch(() => slug.value, fetch)

    fetch()

    return {
      data,
    }
  },
})
</script>

<style lang="scss" scoped>
.tip {
  position: relative;
  background-color: $c-white;

  ::v-deep {
    .header__title,
    .header__infos__item__label {
      .branch-horizon &,
      .branch-route & {
        color: $regal-blue;
      }
    }
  }
}

[class*='tip-inner--'],
.tip-inner {
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: $spacing * 3 0;
  z-index: 0;

  @include mq(s) {
    width: calc(100% - #{$spacing * 4});
  }

  &[class*='--large'] {
    max-width: 60rem;
    @include mq(m) {
      max-width: 72rem;
    }
  }
}

[class*='tip__content--'],
.tip__content {
  margin: 0 $spacing * 1.35;

  @include mq(m) {
    margin: 0;
  }
}

.tip__content h4 {
  margin-top: $spacing * 1.5;
  margin-bottom: $spacing / 4;

  &:first-child {
    margin-top: 0;
  }
}

.tip__content div {
  margin: 0 0 $spacing * 1.5;
}

.tip__content ul {
  list-style: none;
  padding: 0;
  margin: 0 0 $spacing * 1.5;
}

.tip__content li {
  display: flex;

  & + li {
    margin-top: $spacing / 2;
  }
}

.tip__content li::before {
  content: '\2022';
  color: $regal-blue;
  display: inline-block;
  width: 6px;
  margin-right: $spacing / 2;
}

.tip__content__text {
  ::v-deep(p) {
    margin-bottom: $spacing / 2;
  }
}

.tip__content__link-outer {
  margin-top: $spacing * 1.5;
}

.tip__content__link {
  position: relative;
  padding: $spacing * 0.75 $spacing;
  background-color: $athens-gray;
}

.tip__content__link-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tip__content__link__trigger {
  @include get-all-space;
}
</style>
